@tailwind base;
@tailwind components;
@tailwind utilities;

.red{
    @apply bg-success;
}


@layer base {
    h1 {
      @apply text-5xl;
      font-weight: bold;
    }
    h2 {
      @apply text-4xl;
      font-weight: bold;
    }
    p{
      padding-bottom: 1em;
    }
    li{
      list-style:disc;
    }
  }
  
  html {
    scroll-behavior: smooth;
  }
  
  body {
    margin: 0;
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  .blue{
    background-color: hsla(200, 100%, 50%,0.2);
  }
  .hash{
    background-image: repeating-linear-gradient(45deg,hsl(var(--b1)),hsl(var(--b1)) 13px,hsl(var(--b2)) 13px,hsl(var(--b2)) 14px);
  }

  .bigvid{
    @apply w-full;
    position: relative;

}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.text-over {
  position: absolute;
  bottom: 0%;
  right: 0%;
}
.text-upright {
  position: absolute;
  top: 0%;
  right: 0%;
}
.supertext{
  font-size: 7em;
}
.gumroad .cart-button{
  top: 4em;
  @apply bg-neutral;
}





/*  */

.ccvideo-card {
  position: relative;
  width: 600px;
  height: 400px;
}

.ccvideo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ccard-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  text-align: center;
}

.ccard-title {
  color: #fff;
  font-size: 24px;
}

.ccard-description {
  color: #fff;
  font-size: 16px;
}
.paragraph-narrow{
  max-width: 600px;
}

.hd2wide{
  min-width:1920px;
}



h1 {
  @apply text-7xl;
  color: text-primary;
  font-weight: bold;
}
h2 {
  padding-top: 1em;
  padding-bottom: 0.2em;
  @apply text-3xl;
  color:text-primary;
  font-weight: bold;
}
h3 {
  @apply text-lg;
}
/* a {
  @apply text-blue-600 underline;
} */
b {
    font-weight: bold;
}
em{
    font-weight: bold;
    @apply text-accent;
}
p{
  padding-bottom: 1em;
}
ul{
  padding-bottom: 1em;
  list-style: circle;
  margin-left: 1em;
}
ol{
  padding-bottom: 1em;
  list-style: number;
  margin-left: 2em;
}
a button{
  color: text-primary;
  background-color: red;
}

#description:hover{
  background-color:hsla(200, 100%, 50%,0.05);
  transition-duration: 2s;
}
#options:hover{
  background-color:hsla(200, 100%, 50%,0.05);
  transition-duration: 2s;
}

